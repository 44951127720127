<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap36ProgramProfiling"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 36: Program Profiling" image-name="profiling.jpg" image-alt="Program Profiling"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is profiling?</p></li>
<li><p>How to create a CPU profile of your program.</p></li>
<li><p>How to create a Heap profile of your program.</p></li>
<li><p>How to use the <span v-highlightjs><code class="go" v-pre style="display: inline">pprof</code></span> tool to analyze a profile.</p></li>
<li><p>Some common CPU usage optimization techniques.</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Profile</p></li>
<li><p>pprof</p></li>
<li><p>Protocol Buffers</p></li>
<li><p>protoc</p></li>
<li><p>CPU</p></li>
<li><p>Heap profile</p></li>
</ul>
<div id="what-is-profiling" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> What is profiling? <a href="#what-is-profiling"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Profiling is a program optimization technique.“To profile a program” means to collect detailed statistics about how a program runs. Those statistics can be CPU usage, memory allocation, time spent on a program routines, number of function calls ... etc.</p>
<p>But what is the difference with a benchmark? A benchmark collects runtime information about a specific function. Profiling is the collection of statistics for the whole program.</p>
<div id="why-profiling" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Why profiling? <a href="#why-profiling"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Profiling is often used when a performance drop is observed. The tool is used to understand why a program underperforms.</p>
<p>The codebase’s static analysis can be insufficient to detect why the program behaves badly. Benchmarks test an isolated function’s performance; they are insufficient to understand the whole picture.</p>
<p>Profiling can also be a tool to improve program engineering. Go is a relatively performant language, but badly designed programs can suffer from performance issues. Those issues can be easily understood and corrected with profiling’s gracious help.</p>
<div id="getting-started-with-profiling" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Getting started with profiling <a href="#getting-started-with-profiling"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To profile a program, we can use the runtime/pprof package that exposes the necessary API to start and stop profiling.</p>
<p>In this section, we will profile a program that sums integers. The program consists only of a main package with one function named doSum. This function will sum integers from 0 to 787766776 :</p>
<pre v-highlightjs><code class="go" v-pre >package main

import (
    &quot;fmt&quot;
)

func main() {
    result := doSum()
    fmt.Println(result)
}

func doSum()int{
    sum := 0
    for i := 0; i &lt; 787766777; i++ {
        sum += i
    }
    return sum
}</code></pre>
<p>The next step is to add the call to the pprof API inside our main function :</p>
<pre v-highlightjs><code class="go" v-pre >// profiling/getting-started/main.go 

f, err := os.Create(&quot;profile.pb.gz&quot;)
if err != nil {
    log.Fatal(err)
}
err = pprof.StartCPUProfile(f)
if err != nil {
    log.Fatal(err)
}
defer pprof.StopCPUProfile()</code></pre>
<p>This block of code has to be placed inside the <span v-highlightjs><code class="go" v-pre style="display: inline">main</code></span>. It will create a file named <span v-highlightjs><code class="go" v-pre style="display: inline">"profile.pb.gz"</code></span>. Then it will start CPU profiling and write the profile result onto this file (<span v-highlightjs><code class="go" v-pre style="display: inline">pprof.StartCPUProfile(f)</code></span>). At the end of the main function, we will call <span v-highlightjs><code class="go" v-pre style="display: inline">pprof.StopCPUProfile()</code></span>. To improve reading, we have used a deferred statement.</p>
<p>We then have to build our program (we will call the binary <span v-highlightjs><code class="go" v-pre style="display: inline">"gettingstarted"</code></span>) :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build -o gettingstarted main.go</code></pre>
<p>It will create the binary file in the current directory. To collect the data, we then have to launch our program :</p>
<pre v-highlightjs><code class="go" v-pre >$ ./gettingstarted</code></pre>
<p>You can see that a <strong>profile.pb.gz</strong> file has been created. You can try to open the file to visualize the result, but that’s not a very good idea. This file is compressed, and second. We will have to use a tool to visualize our profiling results!</p>
<p>This program is <strong>pprof,</strong> Google has developed it to enable “visualization and analysis of profiling data”<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>. Pprof can read profiles and generate reports about them in a easily readable way. For this getting started, we will just use the pprof command-line interface to visualize our profiling statistics (we will see other visualization techniques later in this chapter) :</p>
<pre v-highlightjs><code class="go" v-pre >$ go tool pprof gettingstarted cpu.profile</code></pre>
<p>We simply invoke <span v-highlightjs><code class="go" v-pre style="display: inline">go tool pprof</code></span> with as first argument, the path to the binary (<span v-highlightjs><code class="go" v-pre style="display: inline">gettingstarted</code></span>) of our program and then the profile file (<span v-highlightjs><code class="go" v-pre style="display: inline">cpu.profile</code></span>). This command will launch the interactive mode. You will have to type commands to display the statistics :</p>
<pre v-highlightjs><code class="go" v-pre >$ go tool pprof gettingstarted profile.pb.gz
File: gettingstarted
Type: cpu
Time: Jan 1, 2021 at 9:27pm (CET)
Duration: 413.54ms, Total samples = 220ms (53.20%)
Entering interactive mode (type &quot;help&quot; for commands, &quot;o&quot; for options)
(pprof)</code></pre>
<p>In the standard output, you can see that pprof displays :</p>
<ul>
<li><p>the name of the binary file (here : <span v-highlightjs><code class="go" v-pre style="display: inline">gettingstarted</code></span>)</p></li>
<li><p>the type of profile (here <span v-highlightjs><code class="go" v-pre style="display: inline">cpu</code></span>)</p></li>
<li><p>the date on which we generated the profile</p></li>
<li><p>the total duration of program execution (<span v-highlightjs><code class="go" v-pre style="display: inline">413ms</code></span>)</p></li>
<li><p>the total samples (we will get back to what a sample is later because it can be confusing)</p></li>
</ul>
<div id="reading-profile-files" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Reading profile files <a href="#reading-profile-files"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To be able to read profile files, we need to install a program provided by google : <strong>protoc</strong>. Why? Because profile files have a specific format, they are <strong>protobuf</strong> files.</p>
<div id="a-word-about-protocol-buffers-protobufpara-word-about" class="anchor"></div>
<h4 data-number="6.0.0.1"><span class="header-section-number">6.0.0.1</span> A word about Protocol Buffers (protobuf)<span id="par:A-word-about" label="par:A-word-about">[par:A-word-about]</span> <a href="#a-word-about-protocol-buffers-protobufpara-word-about"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Protocol Buffers have been developed by Google internally and made open source. It can transform structured data into a lightweight format that can be stored and transmitted over a network. The process of transforming structured data into a specific format is called <strong>serialization</strong>. Data serialized (or encoded) with this method is very light. The format returned is called “binary wire”.</p>
<p>Unlike XML or JSON, the fields’ names are not translated into the data’s serialized version. Therefore the size of the message is <strong>lighter</strong>. You need some sort of <strong>specification</strong> to read a serialized message. The serialization specification is a “proto file”. Proto files have the .proto extension.</p>
<p>The Google team has developed tools in many languages (C++, C#, Dart, Go, Java, Python, Ruby, Objective-C...) to serialize and deserialize data easily. In the next section, we will use one of these tools to deserialize a profile file.</p>
<div id="install-the-protocol-buffer-compiler-protoc" class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> Install the protocol buffer compiler (protoc) <a href="#install-the-protocol-buffer-compiler-protoc"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="download-the-latest-release" class="anchor"></div>
<h4 data-number="6.1.0.1"><span class="header-section-number">6.1.0.1</span> Download the latest release <a href="#download-the-latest-release"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>You first have to download the latest release of the compiler. At the time of writing, the latest version is 3.15.2. I will give you the command to download this specific release in the following lines. Please always download the latest version! To get the latest version number, check the GitHub page https://github.com/protocolbuffers/protobuf/releases.</p>
<p>We will download the already compiled version of the software. Of course, you can build it yourself if you have a C++ compiler installed on your computer, but that’s not the easiest solution.</p>
<p>You can directly download the zipped files on</p>
<pre v-highlightjs><code class="go" v-pre >https://github.com/protocolbuffers/protobuf/releases</code></pre>
<p>You can also use those terminal commands :</p>
<ul>
<li>For <strong>Linux</strong> (64 bits) computers :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >$ curl -OL https://github.com/protocolbuffers/protobuf/releases/download/v3.15.2/protoc-3.15.2-linux-x86_64.zip</code></pre>
<ul>
<li>For <strong>MacOs</strong> users :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >$ curl -OL https://github.com/protocolbuffers/protobuf/releases/download/v3.15.2/protoc-3.15.2-osx-x86_64.zip</code></pre>
<p>For Mac and Linux, we used cURL, a CLI tool to make HTTP requests. We are passing two flags to the command :</p>
<div class="list">
<p>will write the downloaded content to a file (named the same way as the file on the server)</p>
<p>will follow redirection because GitHub is storing the releases on an Amazon AWS S3 bucket (a cloud storage service)</p>
</div>
<p>For <strong>Windows</strong> users the URL to download the release is:</p>
<pre v-highlightjs><code class="go" v-pre >https://github.com/protocolbuffers/protobuf/releases/download/v3.6.1/protoc-3.6.1-win32.zip</code></pre>
<h4 class="unnumbered" id="unzip-the-released-files">Unzip the released files</h4>
<p>For Linux and Mac users, you can use the command line to unzip the files thanks to the <strong>unzip</strong> utility :</p>
<pre v-highlightjs><code class="go" v-pre >$ cd where/you/downloaded/the/zip/file
$ unzip protoc-3.15.2-osx-x86_64.zip -d protoc-3.15.2</code></pre>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">-d</code></span> flag will put the inflated files into the specified directory (it will be created if it does not exist).</p>
<p>For Windows users, I advise you to use the graphical interface to unzip the files.</p>
<div id="add-the-binary-to-your-path" class="anchor"></div>
<h4 data-number="6.1.0.2"><span class="header-section-number">6.1.0.2</span> Add the binary to your path <a href="#add-the-binary-to-your-path"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>For <strong>Linux</strong> and <strong>Mac</strong> users, there is one convenient place where you can put your binaries : <span v-highlightjs><code class="go" v-pre style="display: inline">/usr/local/bind</code></span>. The directory <span v-highlightjs><code class="go" v-pre style="display: inline">/usr/local</code></span> is used to install software locally. The <span v-highlightjs><code class="go" v-pre style="display: inline">bin</code></span> folder will hold local binaries. If you are curious about the UNIX filesystem hierarchy, take a look at the specification: http://refspecs.linuxfoundation.org/FHS_2.3/fhs-2.3.html</p>
<pre v-highlightjs><code class="go" v-pre >$ sudo mv protoc-3.15.2/bin/protoc /usr/local/bin</code></pre>
<p>You will need to sudo to move the executable <span v-highlightjs><code class="go" v-pre style="display: inline">protoc-3.15.2/bin/protoc</code></span> into the <span v-highlightjs><code class="go" v-pre style="display: inline">usr</code></span> folder.</p>
<p>For windows users, you will need to add the binary to the <strong>PATH</strong> environment variable.</p>
<div id="get-the-decoded-version-of-the-profile" class="anchor"></div>
<h2 data-number="6.2"><span class="header-section-number">6.2</span> Get the decoded version of the profile <a href="#get-the-decoded-version-of-the-profile"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To get a decoded version of the profile file, the first step is to get the .proto file. Encoded protocol buffers do not contain field names to reduce their size.</p>
<p>We can find the .proto file on the <strong>pprof</strong> GitHub repository at the following address: https://github.com/google/pprof/blob/master/proto/profile.proto. Go is shipped with a vendored version of pprof that you can find inside the vendor directory inside the go source folders (into src/cmd/vendor/GitHub.com/google/pprof/). However, the profile.proto seems not to be included with the vendored version at the time of writing.</p>
<p>One solution is to clone pprof in your src folder</p>
<pre v-highlightjs><code class="go" v-pre >$ cd /path/to/your/dev/directory
$ git clone https://github.com/google/pprof.git</code></pre>
<p>Then we can use the proto file downloaded. The profile returned is gzipped (it’s compressed). We must first unzip it. To do so, we will use the gunzip command (available for Linux and Mac users) :</p>
<pre v-highlightjs><code class="go" v-pre >$ gunzip profile.pb.gz</code></pre>
<p>This will delete the file profile.pb.gz and create a new file named profile.pb (which is the unzipped version of profile.pb.gz). For Windows users, you can use a GUI tool to achieve this.</p>
<p>Then we can decode the protocol buffer file</p>
<pre v-highlightjs><code class="go" v-pre >$ protoc --decode perftools.profiles.Profile /path/to/your/dev/directory/pprof/proto/profile.proto --proto_path /path/to/your/dev/directory/pprof/proto &lt; profile.pb</code></pre>
<p>On my computer :</p>
<pre v-highlightjs><code class="go" v-pre >$ protoc --decode perftools.profiles.Profile /Users/maximilienandile/Documents/DEV/pprof/proto/profile.proto --proto_path /Users/maximilienandile/Documents/DEV/pprof/proto &lt;  profile.pb</code></pre>
<div id="details-about-the-protoc-command" class="anchor"></div>
<h3 data-number="6.2.1"><span class="header-section-number">6.2.1</span> Details about the protoc command <a href="#details-about-the-protoc-command"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Let’s detail the elements of that command to make it clear :</p>
<ul>
<li><span v-highlightjs><code class="go" v-pre style="display: inline">--decode</code></span> : this flag is waiting for a message type. Our profile is a message in the protocol buffer terminology. Each message has a specific type. In our case, our profile message has the type <span v-highlightjs><code class="go" v-pre style="display: inline">perftools.profiles.Profile</code></span><strong>.</strong> This string does not come from anywhere. If you display the first lines of the profile.proto you will see that it makes sense :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// github.com/google/pprof/blob/master/proto/profile.proto
//...
syntax = &quot;proto3&quot;;

package perftools.profiles;

option java_package = &quot;com.google.perftools.profiles&quot;;
option java_outer_classname = &quot;ProfileProto&quot;;

message Profile {
//...</code></pre>
<p>This is the name of the package then the name of the message : <span v-highlightjs><code class="go" v-pre style="display: inline">perftools.profiles.Profile</code></span></p>
<ul>
<li><p>The next argument to the protoc command is the <strong>path of the proto file</strong></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">--proto_path</code></span> <strong>:</strong> this flag is here to indicate to protoc where it can find .proto files. This path has to contain our .proto file. Otherwise, protoc will not be able to do its job.</p></li>
</ul>
<p>Then we are passing to protoc the encoded message with <span v-highlightjs><code class="go" v-pre style="display: inline">&lt; profile.pb</code></span>. The data in the profile.pb file will be passed as standard input to the protoc program.</p>
<p>Here is the output of this command :</p>
<pre v-highlightjs><code class="go" v-pre >sample_type {
  type: 1
  unit: 2
}
sample_type {
  type: 3
  unit: 4
}
sample {
  location_id: 1
  location_id: 2
  location_id: 3
  value: 14
  value: 140000000
}
sample {
  location_id: 4
  location_id: 2
  location_id: 3
  value: 7
  value: 70000000
}
sample {
  location_id: 5
  location_id: 2
  location_id: 3
  value: 1
  value: 10000000
}
mapping {
  id: 1
  has_functions: true
}
location {
  id: 1
  mapping_id: 1
  address: 17482189
  line {
    function_id: 1
    line: 24
  }
}
location {
  id: 2
  mapping_id: 1
  address: 17482037
  line {
    function_id: 2
    line: 18
  }
}
location {
  id: 3
  mapping_id: 1
  address: 16946166
  line {
    function_id: 3
    line: 201
  }
}
location {
  id: 4
  mapping_id: 1
  address: 17482182
  line {
    function_id: 1
    line: 24
  }
}
location {
  id: 5
  mapping_id: 1
  address: 17482186
  line {
    function_id: 1
    line: 25
  }
}
function {
  id: 1
  name: 5
  system_name: 5
  filename: 6
}
function {
  id: 2
  name: 7
  system_name: 7
  filename: 6
}
function {
  id: 3
  name: 8
  system_name: 8
  filename: 9
}
string_table: &quot;&quot;
string_table: &quot;samples&quot;
string_table: &quot;count&quot;
string_table: &quot;cpu&quot;
string_table: &quot;nanoseconds&quot;
string_table: &quot;main.doSum&quot;
string_table: &quot;/Users/maximilienandile/go/src/go_book/profiling/gettingstarted/main.go&quot;
string_table: &quot;main.main&quot;
string_table: &quot;runtime.main&quot;
string_table: &quot;/usr/local/go/src/runtime/proc.go&quot;
time_nanos: 1546864261276935000
duration_nanos: 417027943
period_type {
  type: 3
  unit: 4
}
period: 10000000</code></pre>
<p>We can see that this file define :</p>
<ul>
<li><p>Sample types</p></li>
<li><p>Samples</p></li>
<li><p>Mappings</p></li>
<li><p>Locations</p></li>
<li><p>Functions</p></li>
<li><p>A " string table"</p></li>
<li><p>The properties time_nanos, a period type, and the property period.</p></li>
</ul>
<p>In the next sections, you will understand the usage of those properties.</p>
<div id="what-is-a-call-stack" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> What is a call stack? <a href="#what-is-a-call-stack"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="what-is-the-call-stack" class="anchor"></div>
<h4 data-number="7.0.0.1"><span class="header-section-number">7.0.0.1</span> What is the call stack <a href="#what-is-the-call-stack"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>A stack is a pile of objects. In real life, you can make a stack with woods, with glasses of champagne, or with everything that can be stacked.</p>
<p>In computer science, we are stacking function calls. When a program executes, it starts with a function. The main function is the first function executed. And then we will call other functions, that will call other functions...</p>
<p>When your program runs, the call stack will grow... You can get the call stack by using the <span v-highlightjs><code class="go" v-pre style="display: inline">debug</code></span> package.</p>
<p>The call stack of a program is an ordered list of currently running functions.</p>
<div id="call-stack-example-how-to-print-the-stack" class="anchor"></div>
<h4 data-number="7.0.0.2"><span class="header-section-number">7.0.0.2</span> Call stack example: how to print the stack <a href="#call-stack-example-how-to-print-the-stack"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Let’s take an example. In the next listing, you can see a sample application that defines a main function and two other functions <span v-highlightjs><code class="go" v-pre style="display: inline">firstFunctionToBeCalled</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">secondFunctionToBeCalled</code></span>. The main function calls <span v-highlightjs><code class="go" v-pre style="display: inline">firstFunctionToBeCalled</code></span> that calls <span v-highlightjs><code class="go" v-pre style="display: inline">secondFunctionToBeCalled</code></span>. In this last function, we will print the stack.</p>
<pre v-highlightjs><code class="go" v-pre >// profiling/stack/main.go 
package main

import &quot;runtime/debug&quot;

func main() {
    firstFunctionToBeCalled()
}

func firstFunctionToBeCalled(){
    secondFunctionToBeCalled()
}

func secondFunctionToBeCalled(){
    debug.PrintStack()
}</code></pre>
<p>The previous program outputs :</p>
<pre v-highlightjs><code class="go" v-pre >$ go run main.go
goroutine 1 [running]:
runtime/debug.Stack(0xc00000e1b0, 0x1, 0x1)
    /usr/local/go/src/runtime/debug/stack.go:24 +0xa7
runtime/debug.PrintStack()
    /usr/local/go/src/runtime/debug/stack.go:16 +0x22
main.secondFunctionToBeCalled()
    /Users/maximilienandile/go/src/go_book/profiling/stack/main.go:14 +0x20
main.firstFunctionToBeCalled()
    /Users/maximilienandile/go/src/go_book/profiling/stack/main.go:10 +0x20
main.main()
    /Users/maximilienandile/go/src/go_book/profiling/stack/main.go:6 +0x20</code></pre>
<p>You can read the stack from end to start (from <span v-highlightjs><code class="go" v-pre style="display: inline">main.main</code></span> to <span v-highlightjs><code class="go" v-pre style="display: inline">runtime/debug.Stack</code></span>). Everything starts with the main. Then the stack grows...</p>
<p>The call stack is then parsed and used to give sense to the measure that was made by the profiler.</p>
<div id="cpu-time" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> CPU time <a href="#cpu-time"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In this section, I will try to explain to you what CPU time is. Without a clear understanding of this notion, the next sections will be hard to understand.</p>
<p>The CPU time represents the Central Processing Unit’s time (CPU) to execute the set of instructions defined in your program. The microprocessor handles those instructions. The more your program is complex and makes intensive calculations, the more CPU time you need.</p>
<p>We can split CPU time into two subcategories:</p>
<ul>
<li><p>CPU user time</p></li>
<li><p>CPU system time</p></li>
</ul>
<p>Let’s take examples to understand those concepts better.</p>
<pre v-highlightjs><code class="go" v-pre >// profiling/understanding-CPU-profile/main.go
package main

import (
    &quot;fmt&quot;
    &quot;log&quot;
    &quot;os&quot;
    &quot;runtime/pprof&quot;
)

func main() {
    // set CPU profiling (1)
    f, err := os.Create(&quot;profile.pb.gz&quot;)
    if err != nil {
        log.Fatal(err)
    }
    err = pprof.StartCPUProfile(f)
    if err != nil {
        log.Fatal(err)
    }
    defer pprof.StopCPUProfile()
    // CPU intensive operation (2)
    test := 0
    for i := 0; i &lt; 1000000000; i++ {
        test = i
    }
    fmt.Println(test)
}</code></pre>
<p>In the previous listing, we begin with the standard syntax to start CPU profiling on our program. Then we have developed a for loop that iterates on numbers from 0 to 1000000000 (excluded). Inside the for loop, we are changing the value of a variable test to the value of i (which is the counter variable).</p>
<p>Let’s build our program and then launch it :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build main.go
$ ./main
999999999</code></pre>
<p>How long does the program take to run? We can relaunch it with the time utility (for Mac and Linux users). The command will execute the program and display statistics :</p>
<pre v-highlightjs><code class="go" v-pre >$ time ./main
real    0m0.629s
user    0m0.518s
sys     0m0.005s</code></pre>
<p>How to interpret this output?</p>
<ul>
<li><p><span class="math inline">0m0.629s</span> : the <strong>total time</strong>, which is the elapsed time between invocation and program termination</p></li>
<li><p><span class="math inline">0m0.518s</span> seconds correspond to the <strong>user CPU time</strong>. This time corresponds to the time the CPU (processor) was busy executing instructions outside the kernel (user space).</p></li>
<li><p><span class="math inline">0m0.005s</span> seconds correspond to <strong>system CPU time</strong>. This time measure corresponds to the time taken by the CPU to execute commands in the kernel space, for instance, system calls (opening a file, for instance)</p></li>
</ul>
<p>In the figure <a href="#fig:CPU-time-repartition" data-reference-type="ref" data-reference="fig:CPU-time-repartition">1</a> you can see the repartition between the user and system CPU time.</p>
<figure>
<b-img :src="require('@/assets/images/cpu_time_graph.png')" alt="CPU time repartition[fig:CPU-time-repartition]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">CPU time repartition<span id="fig:CPU-time-repartition" label="fig:CPU-time-repartition">[fig:CPU-time-repartition]</span></figcaption>
</figure>
<p>You can see that system calls take only 1 percent of CPU time. 99% of the CPU time happens on the user space.</p>
<p>To see how we can change those percentages, we can make this time a program that performs a lot of system calls. By chance, we have at our disposal the <strong>syscall</strong> package :</p>
<pre v-highlightjs><code class="go" v-pre >// profiling/system-call/main.go
package main

import (
    &quot;fmt&quot;
    &quot;io/ioutil&quot;
    &quot;log&quot;
    &quot;syscall&quot;
    &quot;time&quot;
)

func main() {
    for i := 0; i &lt; 100; i++ {
        fileName := fmt.Sprintf(&quot;/tmp/file%d&quot;, time.Now().UnixNano())
        err := ioutil.WriteFile(fileName, []byte(&quot;test&quot;), 0644)
        if err != nil {
            log.Fatal(err)
        }
        err = syscall.Chmod(fileName, 0444)
        if err != nil {
            log.Fatal(err)
        }
    }
}</code></pre>
<p>Here we have a for loop that will create 100 files. We are building the path of the file with an <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Sprintf</code></span>. Each path is <span v-highlightjs><code class="go" v-pre style="display: inline">/tmp/fileXXX</code></span> where XXX represents the number of nano secondes since UNIX epoch time (number of nanoseconds elapsed since 00:00:00 Thursday, 1 January 1970).</p>
<p>Then we are creating the file with the help of <span v-highlightjs><code class="go" v-pre style="display: inline">ioutil.Writefile</code></span>. This util will perform two syscalls (Open and Write). Then when the file is created, we will change its mode to <span v-highlightjs><code class="go" v-pre style="display: inline">0444</code></span> with the help of the system call <span v-highlightjs><code class="go" v-pre style="display: inline">Chmod</code></span>.</p>
<p>This is a lot of system calls! let’s see what happens to our time statistics :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build main.go
$ time ./main

real    0m0.031s
user    0m0.004s
sys     0m0.023s</code></pre>
<p>The user line corresponds to only 0.004 seconds, whereas the system line correspond to 0.023 seconds. On figure <a href="#fig:CPU-time-repartition-1-prog2" data-reference-type="ref" data-reference="fig:CPU-time-repartition-1-prog2">2</a> you can see the repartition visually. CPU time for system calls now represents 85,2% of total CPU time!</p>
<figure>
<b-img :src="require('@/assets/images/cpu_syscall_impact.png')" alt="CPU time repartition program 2[fig:CPU-time-repartition-1-prog2]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">CPU time repartition program 2<span id="fig:CPU-time-repartition-1-prog2" label="fig:CPU-time-repartition-1-prog2">[fig:CPU-time-repartition-1-prog2]</span></figcaption>
</figure>
<div id="kernel" class="anchor"></div>
<h2 data-number="8.1"><span class="header-section-number">8.1</span> Kernel <a href="#kernel"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In this section, we used the term “kernel”. Kernel refers to the central component of an operating system. The kernel manages the system resources. It also manages the different hardware components of the computer. When we are doing a system call, we use the kernel facilities. For instance, opening a file in Go will trigger a system call that the kernel will handle.</p>
<div id="what-is-a-sample" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> What is a sample <a href="#what-is-a-sample"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>You have seen that a profile consists of numerous samples in the decoded profile. The notion of sample can be confusing, so I want to make it clear.</p>
<p>A sample is a measurement. This measure is made at a certain time during the profiling process. When we profile a program, we collect measurements, and those measures are materialized in the profile by samples. In figure <a href="#fig:Schematic-view-of-profile" data-reference-type="ref" data-reference="fig:Schematic-view-of-profile">3</a> you can see that a profile is composed of samples that contain :</p>
<ul>
<li><p>a measure</p></li>
<li><p>a location and</p></li>
<li><p>optional additional information.</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/what_is_a_profile.png')" alt="Schematic view of a profile[fig:Schematic-view-of-profile]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Schematic view of a profile<span id="fig:Schematic-view-of-profile" label="fig:Schematic-view-of-profile">[fig:Schematic-view-of-profile]</span></figcaption>
</figure>
<p>The measure is not the same if you have a <strong>CPU</strong> profile or a <strong>memory</strong> profile.</p>
<p>When you activate CPU profiling <strong>your program will stop every 100 milliseconds (figure <a href="#fig:Measure-collection-rate" data-reference-type="ref" data-reference="fig:Measure-collection-rate">4</a>).</strong></p>
<figure>
<b-img :src="require('@/assets/images/stop_and_collect_profiling.png')" alt="Measure collection rate when CPU profiling a program[fig:Measure-collection-rate]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Measure collection rate when CPU profiling a program<span id="fig:Measure-collection-rate" label="fig:Measure-collection-rate">[fig:Measure-collection-rate]</span></figcaption>
</figure>
<p>Each time the profiler stops the program :</p>
<ol type="1">
<li><p>it collects data</p></li>
<li><p>the data is parsed, the measure is extracted</p></li>
<li><p>A sample is created.</p></li>
</ol>
<p>The data collected consists in a <strong>call stack</strong>. As you have seen in the last section, the trace will give information about the functions called. The <strong>CPU time</strong> is also measured for each sample.</p>
<p>The pprof tool allows us to visualize the samples as they appear in the profile. To get this data, simply enter the following command in your terminal (you will enter the pprof interactive mode)</p>
<pre v-highlightjs><code class="go" v-pre >$ go tool pprof main profile.pb.gz
File: main
Type: cpu
Time: Jan 13, 2019 at 7:07pm (CET)
Duration: 720.43ms, Total samples = 470ms (65.24%)
Entering interactive mode (type &quot;help&quot; for commands, &quot;o&quot; for options)
(pprof)</code></pre>
<p>Then just type “traces” :</p>
<pre v-highlightjs><code class="go" v-pre >(pprof) traces
File: main
Type: cpu
Time: Jan 13, 2019 at 7:07pm (CET)
Duration: 720.43ms, Total samples = 470ms (65.24%)
-----------+-------------------------------------------------------
     240ms   main.main
             runtime.main
-----------+-------------------------------------------------------
     190ms   main.main
             runtime.main
-----------+-------------------------------------------------------
      30ms   runtime.nanotime
             runtime.sysmon
             runtime.mstart1
             runtime.mstart
-----------+-------------------------------------------------------
      10ms   main.main
             runtime.main
-----------+-------------------------------------------------------</code></pre>
<p>The profile samples are written in text mode. You can see that in our case, we have four samples. The first column represents the samples’ values, the CPU time. The second column is the trace.</p>
<p>We can see that our profiling took 720.43ms to build and that the total samples account for 470ms, representing 65.24% of 720.43ms). The term duration can be confusing. This is not the program duration but the profile duration. In our case, the two figures are close but not equal.</p>
<p>The total execution time of the program is smaller than the profile duration. That’s because the profiling is started after the program begins, and it’s stoped when the main function exits.</p>
<p>The first sample is the table has a value of 240ms of CPU time. The call stack when the data was collected has a length of 2. The first function to be called is runtime.main the second one to be called is main.main. The values are not cumulated in this view.</p>
<div id="pprof-command-line" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Pprof command line <a href="#pprof-command-line"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In this section, we will focus on the pprof command line. As you see in figure <a href="#fig:The-pprof-command" data-reference-type="ref" data-reference="fig:The-pprof-command">5</a> the pprof command line is composed of 4 elements :</p>
<ul>
<li><p>The <strong>output format</strong> for the visualization of the results (ex: pdf, SVG, web ...)</p></li>
<li><p>The <strong>options</strong>that can refine the visualization (for instance, the -show flag will represent only nodes that are matching a regular expression)</p></li>
<li><p>The path to the program binary</p></li>
<li><p>The path to the profile source (which is the protocol buffer file)</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/pprof_command_line.png')" alt="The pprof command line[fig:The-pprof-command]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">The pprof command line<span id="fig:The-pprof-command" label="fig:The-pprof-command">[fig:The-pprof-command]</span></figcaption>
</figure>
<p>At the time of writing, there are 24 output formats available. We will not cover every single format. The more common are <strong>-web</strong> which will generate an SVG graph and output it on the web browser, <strong>-svg</strong> and <strong>-pdf</strong> that will allow you to share your profiles with your colleagues easily. In the next section, we will see some common use cases.</p>
<div id="display-profile-in-a-web-browser" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Display profile in a web browser <a href="#display-profile-in-a-web-browser"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="prerequisite-graphviz-installation" class="anchor"></div>
<h2 data-number="11.1"><span class="header-section-number">11.1</span> Prerequisite: Graphviz installation <a href="#prerequisite-graphviz-installation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You will need to install Graphviz (https://gitlab.com/graphviz/graphviz) on your machine to run the following command.</p>
<ul>
<li><p>Graphviz is available on homebrew for MacOS users : <span v-highlightjs><code class="go" v-pre style="display: inline">brew install graphviz</code></span></p></li>
<li><p>For Linux users : <span v-highlightjs><code class="go" v-pre style="display: inline">apt-get install graphviz</code></span></p></li>
<li><p>For windows, users’ installation instructions are on the official website.</p></li>
</ul>
<div id="note-for-mac-users" class="anchor"></div>
<h4 data-number="11.1.0.1"><span class="header-section-number">11.1.0.1</span> Note for Mac Users <a href="#note-for-mac-users"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>For Mac users, you might have to define first the default application to open SVG. On my computer, for instance, the default app was Latex. If you are in this kind of situation, you can change the default application to open SVG to your favorite browser. Right-click on an SVG file and then click on “Get Info”. The view opened lets you choose the default application to use for open. Select the one you want and then valid the modification.</p>
<div id="execute-the-command" class="anchor"></div>
<h2 data-number="11.2"><span class="header-section-number">11.2</span> Execute the command <a href="#execute-the-command"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To generate an SVG file from your profile and open it in your web browser, you can use the web flag :</p>
<pre v-highlightjs><code class="go" v-pre >go tool pprof -web gettingstarted profile.pb</code></pre>
<p>This will create an SVG file and open your browser to display it. The graph in figure <a href="#fig:Pprof-SVG-CPU" data-reference-type="ref" data-reference="fig:Pprof-SVG-CPU">6</a> will appear.</p>
<figure>
<b-img :src="require('@/assets/images/pprof_svg_getting_started.png')" alt="Pprof SVG CPU profile[fig:Pprof-SVG-CPU]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Pprof SVG CPU profile<span id="fig:Pprof-SVG-CPU" label="fig:Pprof-SVG-CPU">[fig:Pprof-SVG-CPU]</span></figcaption>
</figure>
<p>Let’s detail what is on this graph (take a look at the figure <a href="#fig:Pprof-SVG-explanation" data-reference-type="ref" data-reference="fig:Pprof-SVG-explanation">7</a>)</p>
<figure>
<b-img :src="require('@/assets/images/pprof_svg_explanation.png')" alt="Pprof SVG explanation[fig:Pprof-SVG-explanation]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Pprof SVG explanation<span id="fig:Pprof-SVG-explanation" label="fig:Pprof-SVG-explanation">[fig:Pprof-SVG-explanation]</span></figcaption>
</figure>
<p>You can see in the figure <a href="#fig:Pprof-SVG-explanation" data-reference-type="ref" data-reference="fig:Pprof-SVG-explanation">7</a> that the SVG produced is composed of two main parts.</p>
<p>The first part (a rectangular box) holds the details about the profile :</p>
<ul>
<li><p>The name of the binary profiled</p></li>
<li><p>The type of profile</p></li>
<li><p>The time on which the profile was generated</p></li>
<li><p>The time is taken by the profile (duration)</p></li>
<li><p>An indication of the total samples, which is here returned as a duration, can be confusing.</p></li>
<li><p>The indication about the representativity of the current view:</p>
<ul>
<li><p>labelised "Showing nodes account for X, Y% of Z.</p></li>
<li><p>X represents the number of samples that the nodes displayed represent.</p></li>
<li><p>Z the total samples available in the profile</p></li>
<li><p>Y represents the percentage X/Z %</p></li>
</ul></li>
</ul>
<p>Then on this image, you can see blocks called nodes and arrows. We have a directed graph (which also acyclic, meaning that it does not contain cycles) if we use the correct terminology. Each node in this graph represents a function call. In the figure, we detail what each element of a node means :</p>
<figure>
<b-img :src="require('@/assets/images/zoom_node_explanation.png')" alt="Pprof SVG node significations"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Pprof SVG node significations</figcaption>
</figure>
<p>You got the package’s information, the function called, the sample’s value, and the profile’s total samples on each node. This last statistic is very important. With this stat, you can compare each node’s weight and see where the performance drop is happening.</p>
<div id="interactive-web-interface" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Interactive web interface <a href="#interactive-web-interface"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The profile information generated can be inspected into a nice web interface. To launch it, type :</p>
<pre v-highlightjs><code class="go" v-pre >$ go tool pprof -http localhost:9898 yourBinaryName profile.pb</code></pre>
<p>It will launch a web server on http:localhost:9898, where you can see the statistics.</p>
<figure>
<b-img :src="require('@/assets/images/profile_web_1.png')" alt="Pprof Interative web interface homepage"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Pprof Interative web interface homepage</figcaption>
</figure>
<figure>
<b-img :src="require('@/assets/images/profile_web_2.png')" alt="Pprof view source[fig:Pprof-view-source]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Pprof view source<span id="fig:Pprof-view-source" label="fig:Pprof-view-source">[fig:Pprof-view-source]</span></figcaption>
</figure>
<figure>
<b-img :src="require('@/assets/images/profile_web_2_disasembling.png')" alt="Disassemble view"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Disassemble view</figcaption>
</figure>
<p>The weblist (<a href="#fig:Pprof-view-source" data-reference-type="ref" data-reference="fig:Pprof-view-source">8</a>) is an interesting view. It allows you to see your application’s source code with the profiling statistics. In addition to that, you get the disassembled version of the source code profiled!</p>
<div id="disassembled-what-does-it-mean" class="anchor"></div>
<h4 data-number="12.0.0.1"><span class="header-section-number">12.0.0.1</span> Disassembled? What does it mean <a href="#disassembled-what-does-it-mean"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>When you compile your Go program, you get what it’s called a called “executable”. You can execute with your computer an executable file. An executable is written in machine language. Machine language is not impossible to read, but it’s really difficult. A disassembler is a program that can transform machine language into assembly language. Assembly language is not Go code. It’s a set of instructions that are very close to the architecture of the computer (processor) but also to its operating system.</p>
<p>By visualizing the assembly code produced, you can better understand what is going on under the hood. Assembly is not very popular among the developer community. If we take the Stackoverflow developer survey of 2018, only 7,8% of our colleagues say that they use it, for “professional” developers the figure is lower : 6.8% <b-link class="citation" data-cites="dev-survy-2018" href="#dev-survy-2018" >[@dev-survy-2018]</b-link>.</p>
<div id="what-is-code-optimization" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> What is code optimization? <a href="#what-is-code-optimization"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>This is the process of “modifying a software system to make some aspect of it work more efficiently or use fewer resources.”<b-link class="citation" data-cites="wiki-opti" href="#wiki-opti" >[@wiki-opti]</b-link>.</p>
<p>The main objective of code optimization is to reduce the execution time of program <b-link class="citation" data-cites="chang1991using" href="#chang1991using" >[@chang1991using]</b-link>. Profiling is an interesting tool that we can use to reduce execution time and memory consumption of a program.</p>
<p>To optimize a code, you need to modify it, and sometimes you will need to make important modifications to gain performance. Chang, Mahlke, and Hwu, in a paper published in 1991, made an interesting distinction between two types of code optimization :</p>
<ol type="1">
<li><p>The first one is a modification of portions of code that reduce this particular portion’s execution time without modifying the execution time of any other instructions in the program. For instance, deleting some old code will reduce the execution time of the portion you have optimized; this modification will not impact the other instructions</p></li>
<li><p>The second one is the optimization of portions of code that will increase the execution time of other portions of code. Let’s take an example, imagine that you have a for loop, and inside this loop, you are making a useless computation. You can extract this computation outside the loop. If you do so, you will reduce the loop’s execution time, but you will increase the execution time outside the loop.</p></li>
</ol>
<div id="common-optimization-techniques" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Common optimization techniques <a href="#common-optimization-techniques"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Code optimization is mostly acquired with experience. But there are some common optimization techniques that you should know by heart!</p>
<div id="dead-code-removal" class="anchor"></div>
<h2 data-number="14.1"><span class="header-section-number">14.1</span> Dead code removal <a href="#dead-code-removal"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Introduction of dead code is not easy with go. The go compiler will block if you are declaring a variable without using it. That’s a very good thing. But it does not mean that you cannot introduce some dead code fragments.</p>
<p>Here is an example :</p>
<pre v-highlightjs><code class="go" v-pre >// profiling/classic-opti/deadCode/main.go
package main

import &quot;fmt&quot;

func main() {
    fmt.Println(multiply(1, 9))
}

func multiply(x, y int) int {
    a := x + 1
    b := a + 2*y
    b = b * b
    return x * y
}</code></pre>
<p>Here we have the function multiply that takes two integers (x and y). This function will multiply both integers and return the result.</p>
<p>In the body of this function, we define <span v-highlightjs><code class="go" v-pre style="display: inline">a</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">b</code></span> . Those two variables are useless and are maybe the heritage of old computation that was done before. Do not be sentimental delete those useless lines:</p>
<pre v-highlightjs><code class="go" v-pre >// profiling/classic-opti/deadCodeV2/main.go 
//...
func multiply(x,y int) int {
    return x * y
}</code></pre>
<div id="loop-exit-optimization" class="anchor"></div>
<h2 data-number="14.2"><span class="header-section-number">14.2</span> Loop exit optimization <a href="#loop-exit-optimization"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>This optimization technique is very easy to put into practice. If you are confronted with a new code, examine the loops and their exit conditions closely.</p>
<p>Loops are often used to iterate over arrays or slice to find a specific element when you don’t know its index. Search for <strong>useless</strong> iterations: when you find what you are looking for, there is no need to continue the loop!</p>
<p>Let’s take an example. Imagine that your job is to find an element in a slice and report its index. You might produce this program</p>
<pre v-highlightjs><code class="go" v-pre >// profiling/classic-opti/loopExit/main.go 
//...

// s is a slice of uint32
var search uint32 = 4285020338
var foundIndex int
var found bool
for j := 0; j &lt; len(s); j++ {
    if s[j] == search {
        found = true
        foundIndex = j
    }
}
if found {
    fmt.Printf(&quot;found index is %d&quot;, foundIndex)
} else {
    fmt.Println(&quot;index not found&quot;)
}</code></pre>
<p>In this listing we begin by the definition of 3 variables : <span v-highlightjs><code class="go" v-pre style="display: inline">search</code></span> (which will hold the number we are trying to locate in the slice <span v-highlightjs><code class="go" v-pre style="display: inline">s</code></span>), <span v-highlightjs><code class="go" v-pre style="display: inline">foundIndex</code></span> that will hold the index of the retrieved element and “found”, which is a boolean true if the number has been found.</p>
<p>Then take a close look at the for loop... We are iterating over all the elements of the slice. At each iteration, we check if the element located at the current index is equal to the integer that we look for. If so, we are setting the variables found and <span v-highlightjs><code class="go" v-pre style="display: inline">foundIndex</code></span>ex to their expected values.</p>
<p>What is the interest in continuing the loop if we find the element? We will make some additional comparisons that will cost some time. We can exit the loop directly if we found the element :</p>
<pre v-highlightjs><code class="go" v-pre >// profiling/classic-opti/loopExitV2/main.go 
//...

for j := 0; j &lt; len(s); j++ {
    if s[j] == search {
        found = true
        foundIndex = j
        break
    }
}</code></pre>
<p>We use the <span v-highlightjs><code class="go" v-pre style="display: inline">break</code></span> statement that will end the for loop.</p>
<div id="loop-invariant-code-removal" class="anchor"></div>
<h2 data-number="14.3"><span class="header-section-number">14.3</span> Loop invariant code removal <a href="#loop-invariant-code-removal"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>This optimization technique consists of moving invariant instructions outside the loops. Those instructions have a <strong>source</strong> operand that does not change within the loop <b-link class="citation" data-cites="chang1991using" href="#chang1991using" >[@chang1991using]</b-link>.</p>
<p>To make this clear, we will take an example. The program we have to create must compute the total turnover of a chain of stores. The stores transmit to the holding their turnover figures. For each store costs have to be deducted from the turnover figure transmitted :</p>
<pre v-highlightjs><code class="go" v-pre >// profiling/classic-opti/loopInvariant/main.go 
// ...
var turnover uint32
for i := 0; i &lt; len(s); i++ {
    var costs = 950722 + 12*uint32(len(s))
    turnover = turnover + s[i] - costs
}
fmt.Println(turnover)</code></pre>
<p>We can note that inside the for loop, we are defining at each iteration the costs variable. This variable is set with the result of a complex computation :</p>
<pre v-highlightjs><code class="go" v-pre >var costs = 950722 + 12*uint32(len(s))</code></pre>
<p>The cost computation is still the same for each store. It does not depend on the turnover of the stores. The source operand (<span v-highlightjs><code class="go" v-pre style="display: inline">950722 + 12*uint32(len(s))</code></span>) does not change; it’s invariant. We can extract this computation outside this for loop. The program will execute it only one time, and therefore we will spare some CPU time :</p>
<pre v-highlightjs><code class="go" v-pre >// profiling/classic-opti/loopInvariantV2/main.go 
//...

var turnover uint32
var costs = 950722 + 12*uint32(len(s))
for i := 0; i &lt; len(s); i++ {
    turnover = turnover + s[i] - costs
}
fmt.Println(turnover)</code></pre>
<div id="loop-fusion" class="anchor"></div>
<h2 data-number="14.4"><span class="header-section-number">14.4</span> Loop Fusion <a href="#loop-fusion"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>If your program is composed of two loops (or more) that :</p>
<ul>
<li><p>are executed under the same conditions</p></li>
<li><p>are independent (the execution of one loop do not depend on the execution of the other one)</p></li>
<li><p>have the same number of executions</p></li>
</ul>
<p>We can merge those loops into one single loop <b-link class="citation" data-cites="thomas1971catalogue" href="#thomas1971catalogue" >[@thomas1971catalogue]</b-link>. Here is a small example :</p>
<pre v-highlightjs><code class="go" v-pre >// profiling/classic-opti/loopFusion/main.go 
//...

var costs float64
for i := 0; i &lt; len(s); i++ {
    costs += 0.2*float64(s[i])
}
var turnover uint32
for i := 0; i &lt; len(s); i++ {
    turnover += s[i]
}</code></pre>
<p>Those two loops have the same number of iterations, they are independent, and they are both executed. We can merge them :</p>
<pre v-highlightjs><code class="go" v-pre >// profiling/classic-opti/loopFusionV2/main.go 
//...


var costs float64
var turnover uint32
for i := 0; i &lt; len(s); i++ {
    costs += 0.2*float64(s[i])
    turnover += s[i]
}</code></pre>
<p>We will spare CPU time (instead of looping over the slice two times, we only loop through the values of s one single time).</p>
<div id="constant-folding" class="anchor"></div>
<h2 data-number="14.5"><span class="header-section-number">14.5</span> Constant folding <a href="#constant-folding"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Constant folding is a compiler optimization technique that consists of evaluating the values of constants during the compilation of the program and not during its execution.</p>
<p>The compiler already does this for us. But it’s useful to remember! If your program defines a variable to hold the result of an operation, think about the opportunity to create a constant instead. The operation will be performed during the compilation, and therefore you will spare precious CPU time.</p>
<p>Let’s take the example of an upload function that is responsible for transferring files from one server to another one :</p>
<pre v-highlightjs><code class="go" v-pre >// profiling/classic-opti/constantFolding/main.go 
// ...

func upload(files []File)(error){
    uploadLimit := 10*2048/2
    for _,file := range files{
        if file.Size &gt; uploadLimit {
            return errors.New(&quot;the upload limit has been reached&quot;)
        }
        // upload the file
    }
    return nil
}</code></pre>
<p>Here the variable uploadLimit is set to: <span v-highlightjs><code class="go" v-pre style="display: inline">10*2048/2</code></span></p>
<p>The problem here is that each time we are going to upload a file to the server, we will compute again the uploadLimit, which is a constant. We can replace it with a constant to make Go compute it when the program is compiled.</p>
<pre v-highlightjs><code class="go" v-pre >// profiling/classic-opti/constantFoldingV2/main.go 
// ....

const uploadLimit =10*2048/2

func upload(files []File)(error){
    for _,file := range files{
        if file.Size &gt; uploadLimit {
            return errors.New(&quot;the upload limit has been reached&quot;)
        }
        // upload the file
    }
    return nil
}</code></pre>
<div id="real-world-optimization-problem" class="anchor"></div>
<h1 data-number="15"><span class="header-section-number">15</span> Real-world optimization problem <a href="#real-world-optimization-problem"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We have talked a lot about profiling in the previous sections; it’s time to apply what we have learned to a real-world optimization problem.</p>
<div id="presentation-of-the-program-to-optimize" class="anchor"></div>
<h2 data-number="15.1"><span class="header-section-number">15.1</span> Presentation of the program to optimize <a href="#presentation-of-the-program-to-optimize"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Imagine you are a developer in a large worldwide hotel group. Your company has 30.000 hotels around the world. You are asked to build a program to compute the global group turnover. Each hotel is sending to the central office a report at the end of each month. The reporting is a JSON file. Here is an example :</p>
<pre v-highlightjs><code class="go" v-pre >{
    &quot;name&quot;: &quot;The Ultra Big Luxury Hotel&quot;,
    &quot;reservations&quot;: [
      {
        &quot;id&quot;: 0,
        &quot;duration&quot;: 1,
        &quot;rateId&quot;: 0
      },
      {
        &quot;id&quot;: 1,
        &quot;duration&quot;: 5,
        &quot;rateId&quot;: 1
      }
    ],
    &quot;rates&quot;: [
      {
        &quot;id&quot;: 0,
        &quot;price&quot;: 300
      },
      {
        &quot;id&quot;: 1,
        &quot;price&quot;: 244
      }
    ]
}</code></pre>
<p>This is the reporting of the “The Ultra Big Luxury Hotel”. The hotel has two reservations. Each reservation have an internal id; a duration expressed in night and a rateId. Each hotel maintains its rate list. The reservation number 0 has a duration of 1 day with the rateId 0, which means 300$ per night. The second reservation has a duration of 5 days for a rate equal to 244$/night.</p>
<p>The reportings are aggregated into a big JSON file. We have to develop a program that will read the JSON file and then compute the whole group’s total turnover.</p>
<pre v-highlightjs><code class="go" v-pre >// profiling/program-optimization/cmd/main.go 
// ...

type Reporting struct {
    HotelReportings []HotelReporting
    ExchangeRates   []ExchangeRate
}

type HotelReporting struct {
    HotelId      int
    HotelName    string
    Reservations []Reservation
    Rates        []Rate
}

type ExchangeRate struct {
    RateUSD float64
    HotelId int
}
type Reservation struct {
    Id       uint
    Duration uint
    RateId   uint
}
type Rate struct {
    Id    uint
    Price uint
}</code></pre>
<p>We first create our type struct to unmarshal the JSON file :</p>
<pre v-highlightjs><code class="go" v-pre >data, err := ioutil.ReadFile(&quot;/Users/maximilienandile/go/src/go_book/profiling/programOptimization/cmd/output.json&quot;)
if err != nil {
    panic(err)
}
reportings := make([]Reporting, 0)
err = json.Unmarshal(data, &amp;reportings)
if err != nil {
    panic(err)
}</code></pre>
<p>We then load the file and unmarshal the JSON into reportings which is a slice of Reporting values. We will then implement the main logic :</p>
<pre v-highlightjs><code class="go" v-pre >var groupTurnover float64
for _, hotelReport := range r.HotelReportings {
    turnover, err := getTurnover(hotelReport,r.ExchangeRates)
    if err != nil {
        panic(err)
    }
    groupTurnover += turnover
}
fmt.Printf(&quot;Group Turnover %f\n&quot;, groupTurnover)</code></pre>
<p>We iterate over the reportings, and then for each reporting, we will call the function getTurnover that will compute the turnover for a particular hotel reporting.</p>
<pre v-highlightjs><code class="go" v-pre >func getTurnover(reporting HotelReporting, exchangeRates []ExchangeRate) (float64, error) {
    turnoverPerReservation := []float64{}
    for _, reservation := range reporting.Reservations {
        ratePerNight, err := getRatePerNight(reporting.Rates, reservation.RateId)
        if err != nil {
            panic(err)
        }
        xr,err := getExchangeRate(reporting.HotelId,exchangeRates)
        if err != nil {
            panic(err)
        }
        turnoverResaUSD := float64(ratePerNight*reservation.Duration)*xr

        turnoverPerReservation = append(turnoverPerReservation, turnoverResaUSD)

    }
    return computeTotalTurnover(turnoverPerReservation), nil
}

func computeTotalTurnover(turnoverPerReservation []float64) float64 {
    var totalTurnover float64
    for _, t := range turnoverPerReservation {
        totalTurnover += t
    }
    return totalTurnover
}

func getRatePerNight(rates []Rate, rateId uint) (uint, error) {
    var found bool
    var price uint
    for _, rate := range rates {
        if rate.Id == rateId {
            found = true
            price = rate.Price
        }
    }
    if found {
        return price, nil
    } else {
        return 0, errors.New(&quot;Impossible to retrieve rate per night&quot;)
    }
}

func getExchangeRate(hotelId int, exchangeRates []ExchangeRate) (float64, error) {
    var found bool
    var rate float64
    for _, xr := range exchangeRates {
        if xr.HotelId == hotelId {
            found = true
            rate = xr.RateUSD
        }
    }
    if found {
        return rate, nil
    } else {
        return 0, errors.New(&quot;Impossible to retrieve exchange rate&quot;)
    }
}</code></pre>
<p>In the previous listing, we have our three main functions that will compute the turnover :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">getTurnover</code></span> function will create a slice holding the turnover for each reservation of the hotel. To get this turnover, the function will need to retrieve the rate per night applied by the hotel (it will call <span v-highlightjs><code class="go" v-pre style="display: inline">getRatePerNight</code></span>) and the hotel currency exchange rate to convert the amount to USD (<span v-highlightjs><code class="go" v-pre style="display: inline">getExchangeRate</code></span>).</p></li>
<li><p>Then it will multiply the number of nights (duration) by the rate to get the amount of money generated by the reservation. This amount is in local money; the program will multiply it by the exchange rate USD.</p></li>
<li><p>The next step is, to sum up, the amounts that are in the <span v-highlightjs><code class="go" v-pre style="display: inline">turnoverPerReservation</code></span> slice. To do so, we call <span v-highlightjs><code class="go" v-pre style="display: inline">computeTotalTurnover</code></span> .</p></li>
</ul>
<div id="first-run" class="anchor"></div>
<h2 data-number="15.2"><span class="header-section-number">15.2</span> First run <a href="#first-run"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To test our program, we have generated fake data :</p>
<ul>
<li><p>5.000 hotels</p></li>
<li><p>70 rates per hotel</p></li>
<li><p>1.000 reservations per hotel.</p></li>
</ul>
<p>Let’s build it :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build main.go</code></pre>
<p>And then we can run it (with the time utility)</p>
<pre v-highlightjs><code class="go" v-pre >$ time ./main --rates 70 --hotels 5000 --resas 1000
real    0m27.659s
user    0m27.423s
sys     0m0.124s</code></pre>
<p>The program tool <strong>27.659s</strong> to execute (data sample generation time is included)</p>
<div id="pprof-analysis" class="anchor"></div>
<h2 data-number="15.3"><span class="header-section-number">15.3</span> pprof analysis <a href="#pprof-analysis"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>When the program has been run, the profile has been written to the gzipped file named profile.pb.gz. We can launch pprof to see the profiling data :</p>
<pre v-highlightjs><code class="go" v-pre >$ go tool pprof main profile.pb.gz
File: main
Type: cpu
Time: Jan 15, 2019 at 1:42pm (CET)
Duration: 27.18s, Total samples = 25.72s (94.63%)
Entering interactive mode (type &quot;help&quot; for commands, &quot;o&quot; for options)
(pprof)</code></pre>
<p>The last command will open the interactive mode. You can see that the samples collected cover 90.81% of the profile duration. We will begin by displaying the top 10 functions regarding CPU time.</p>
<div id="the-top-command" class="anchor"></div>
<h2 data-number="15.4"><span class="header-section-number">15.4</span> The top command <a href="#the-top-command"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >(pprof) top
Showing nodes accounting for 25.58s, 99.46% of 25.72s total
Dropped 25 nodes (cum &lt;= 0.13s)
Showing top 10 nodes out of 23
      flat  flat%   sum%        cum   cum%
    16.87s 65.59% 65.59%     16.87s 65.59%  main.getExchangeRate
     6.15s 23.91% 89.50%      6.15s 23.91%  runtime.memclrNoHeapPointers
     2.03s  7.89% 97.40%      2.03s  7.89%  runtime.nanotime
     0.24s  0.93% 98.33%      0.24s  0.93%  main.getRatePerNight
     0.21s  0.82% 99.14%      0.21s  0.82%  runtime.(*mspan).init (inline)
     0.07s  0.27% 99.42%     23.62s 91.84%  main.getTurnover
     0.01s 0.039% 99.46%      6.44s 25.04%  runtime.growslice
         0     0% 99.46%     23.63s 91.87%  main.main
         0     0% 99.46%      6.42s 24.96%  runtime.(*mcache).nextFree
         0     0% 99.46%      6.42s 24.96%  runtime.(*mcache).nextFree.func1</code></pre>
<figure>
<b-img :src="require('@/assets/images/top_explanation.png')" alt="Pprof top command explanation[fig:Pprof-top-command]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Pprof top command explanation<span id="fig:Pprof-top-command" label="fig:Pprof-top-command">[fig:Pprof-top-command]</span></figcaption>
</figure>
<p>On the figure <a href="#fig:Pprof-top-command" data-reference-type="ref" data-reference="fig:Pprof-top-command">9</a> you can see explanations about the output of the command. Keep in mind the signification of <strong>cum</strong> and <strong>flat.</strong></p>
<dl>
<dt>flat</dt>
<dd><p>The <strong>flat time of a function</strong> correspond to the sum of CPU time that was spent during the function execution during the profiling of the program.</p>
</dd>
<dt>cum</dt>
<dd><p>stands for <strong>cumulative</strong>. This is the sum of CPU time taken by the execution of the function <strong>and</strong> also, the time is taken by the function when it’s waiting for another function to return.</p>
</dd>
</dl>
<p>Let’s take an example. We can display all the traces that contain the function main.getTurnover with the following command (using the pprof interactive mode)</p>
<pre v-highlightjs><code class="go" v-pre >(pprof) traces focus main.getTurnover</code></pre>
<p>In the following section, you can see an extract of 3 traces that are displayed.</p>
<pre v-highlightjs><code class="go" v-pre >-----------+-------------------------------------------------------
     1.58s   runtime.memmove
             runtime.growslice
             main.getTurnover
             main.main
             runtime.main
-----------+-------------------------------------------------------
      70ms   runtime.memclrNoHeapPointers
             runtime.growslice
             main.getTurnover
             main.main
             runtime.main
-----------+-------------------------------------------------------
      20ms   main.getTurnover
             main.main
             runtime.main</code></pre>
<p>The <strong>first trace</strong> account for 1.58s.</p>
<p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">main.getTurnover</code></span> is in the third position (starting from the beginning of the call stack). This trace says that the main (<span v-highlightjs><code class="go" v-pre style="display: inline">main.main</code></span>) function has called the function <span v-highlightjs><code class="go" v-pre style="display: inline">main.getTurnover</code></span> that has called the function <span v-highlightjs><code class="go" v-pre style="display: inline">runtime.growslice</code></span>.… In this sample, the function getTurnover is waiting for the function runtime.grow to return (which is waiting for runtime.memove to return). We can add this to the <strong>cumulative duration.</strong></p>
<p>For the <strong>second call stack</strong> the function <span v-highlightjs><code class="go" v-pre style="display: inline">getTurnover</code></span> is also part of the call stack, also in the third position. We have to add the duration to the cumulative duration.</p>
<p>For the <strong>third call stack</strong> we have our function at the top of the stack. We can add this to the flat duration. Let’s do some math :</p>
<p>If we add only those call stacks to generate our profile</p>
<ul>
<li><p>the <strong>flat</strong> time of function <span v-highlightjs><code class="go" v-pre style="display: inline">main.getTurnover</code></span> would be equal to 20ms</p></li>
<li><p>whereas the <strong>cumulative</strong> time of <span v-highlightjs><code class="go" v-pre style="display: inline">main.getTurnover</code></span> would be equal to:</p></li>
</ul>
<p><span class="math inline">1.58s+70ms=1.58s+0.07s=1.65s</span></p>
<p>If we analyze all the profile traces, we can make the same observations.</p>
<div id="first-round-of-optimization-loop-exitsubsecopti-loop-exit" class="anchor"></div>
<h2 data-number="15.5"><span class="header-section-number">15.5</span> First round of optimization : loop exit<span id="subsec:opti-loop-exit" label="subsec:opti-loop-exit">[subsec:opti-loop-exit]</span> <a href="#first-round-of-optimization-loop-exitsubsecopti-loop-exit"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>We will focus for this first round on the function <span v-highlightjs><code class="go" v-pre style="display: inline">main.getExchangeRate</code></span> which totalizes 16.87s of the profile recorded CPU time. It represents 65.59% of the cumulated CPU time. We have to improve this function :</p>
<pre v-highlightjs><code class="go" v-pre >func getExchangeRate(hotelId int, exchangeRates []ExchangeRate) (float64, error) {
    var found bool
    var rate float64
    for _, xr := range exchangeRates {
        if xr.HotelId == hotelId {
            found = true
            rate = xr.RateUSD
        }
    }
    if found {
        return rate, nil
    } else {
        return 0, errors.New(&quot;Impossible to retrieve exchange rate&quot;)
    }
}</code></pre>
<ul>
<li><p>This function will iterate over a slice named <span v-highlightjs><code class="go" v-pre style="display: inline">exchangeRates</code></span> which contains all the currency exchange rates for each hotel.</p></li>
<li><p>Each hotel has an exchange rate. In our sample, there are 5.000 hotels. Each time this function is called, the for loop will iterate over the 5.000 rates to find the right one.</p></li>
<li><p>Imagine that we are looking for the exchange rate for the hotel of id 42. If the slice elements are ordered by hotelId, at the 42nd iteration, we are done, the exchange rate has been found.</p></li>
<li><p>But our function will keep iterating over the slice. That’s 5,000-42=4958 useless iterations.</p></li>
</ul>
<p>We could stop the iteration when we found our exchange rate :</p>
<pre v-highlightjs><code class="go" v-pre >if xr.HotelId == hotelId {
    found = true
    rate = xr.RateUSD
    break
}</code></pre>
<p>Here the keyword break will exit the for loop and continue the execution from the closing bracket of the for.</p>
<p>Let’s run the profile again to see if it has improved our performance.</p>
<div id="time-statistics" class="anchor"></div>
<h4 data-number="15.5.0.1"><span class="header-section-number">15.5.0.1</span> Time statistics <a href="#time-statistics"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >real    0m7.690s
user    0m7.498s
sys     0m0.085s</code></pre>
<p>Impressive ! with this simple break statement, we have gained <span class="math inline">27-7=20</span> seconds!</p>
<div id="cpu-profile" class="anchor"></div>
<h4 data-number="15.5.0.2"><span class="header-section-number">15.5.0.2</span> CPU profile <a href="#cpu-profile"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Let’s see the effect on the profile statistics :</p>
<pre v-highlightjs><code class="go" v-pre >Showing nodes accounting for 6.70s, 99.41% of 6.74s total
Dropped 5 nodes (cum &lt;= 0.03s)
Showing top 10 nodes out of 25
      flat  flat%   sum%        cum   cum%
     4.29s 63.65% 63.65%      4.29s 63.65%  main.getExchangeRate
     1.48s 21.96% 85.61%      1.48s 21.96%  runtime.memmove
     0.57s  8.46% 94.07%      0.57s  8.46%  runtime.nanotime
     0.18s  2.67% 96.74%      0.18s  2.67%  main.getRatePerNight
    0.10s  1.48% 98.22%      0.10s  1.48%  runtime.(*mspan).init (inline)
     0.06s  0.89% 99.11%      0.06s  0.89%  runtime.memclrNoHeapPointers
     0.02s   0.3% 99.41%      6.15s 91.25%  main.getTurnover
         0     0% 99.41%      6.15s 91.25%  main.main
         0     0% 99.41%      0.18s  2.67%  runtime.(*mcache).nextFree
         0     0% 99.41%      0.18s  2.67%  runtime.(*mcache).nextFree.func1</code></pre>
<p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">main.getExchangeRate</code></span> is still at the top of the CPU profile. Its flat execution time is now just 4.29s, compared to 16.87s in the previous version; this is an improvement!</p>
<div id="second-round-of-optimization-loop-invariants-v2" class="anchor"></div>
<h2 data-number="15.6"><span class="header-section-number">15.6</span> Second round of optimization: loop invariants (V2) <a href="#second-round-of-optimization-loop-invariants-v2"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The previous CPU profile show that the function <span v-highlightjs><code class="go" v-pre style="display: inline">main.getExchangeRate</code></span> is still one of the most consuming CPU time. Let’s see in which context it’s used :</p>
<pre v-highlightjs><code class="go" v-pre >func getTurnover(reporting HotelReporting, exchangeRates []ExchangeRate) (float64, error) {
    turnoverPerReservation := []float64{}
    for _, reservation := range reporting.Reservations {
        //..
        xr,err := getExchangeRate(reporting.HotelId,exchangeRates)
        //...
    }
    //...
}</code></pre>
<ul>
<li><p>You can see that the function <span v-highlightjs><code class="go" v-pre style="display: inline">getTurnover</code></span> will retrieve the turnover for a particular hotel.</p></li>
<li><p>It will loop over all the hotel reservations.</p></li>
<li><p>And for each hotel reservation, the function will retrieve the hotel exchange rate. That’s not very optimal because the exchange rate does not vary. It is stable for each reservation because it’s defined for each hotel.</p></li>
</ul>
<p>In this code snippet, we have a perfect example of a loop invariant. the variable <span v-highlightjs><code class="go" v-pre style="display: inline">xr</code></span> will always be the same, but we keep retrieving it for each loop iteration.</p>
<p>We can simply extract the call to <span v-highlightjs><code class="go" v-pre style="display: inline">getExchangeRate</code></span> from the for loop. We will place it before the loop begins :</p>
<pre v-highlightjs><code class="go" v-pre >func getTurnover(reporting HotelReporting, exchangeRates []ExchangeRate) (float64, error) {
    turnoverPerReservation := []float64{}
    xr,err := getExchangeRate(reporting.HotelId,exchangeRates)
    for _, reservation := range reporting.Reservations {
        //..
        //...
    }
    //...
}</code></pre>
<p>Let’s see the impact of this simple modification on our program’s performance.</p>
<pre v-highlightjs><code class="go" v-pre >$ go build main
$ time ./main --rates 70 --hotels 20000 --resas 1000</code></pre>
<p>The result is impressive :</p>
<pre v-highlightjs><code class="go" v-pre >real    0m1.090s
user    0m0.847s
sys     0m0.072s</code></pre>
<p>The program takes no 1.090s to execute! Let’s see the results of profiling :</p>
<pre v-highlightjs><code class="go" v-pre >$ go tool pprof main profile_1547640425.pb.gz
File: main
Type: cpu
Time: Jan 16, 2019 at 1:07pm (CET)
Duration: 614.36ms, Total samples = 400ms (65.11%)
Entering interactive mode (type &quot;help&quot; for commands, &quot;o&quot; for options)
(pprof)</code></pre>
<p>First, we note that the samples account for only 400ms (approximately four samples). This is perfectly normal because our program has only one second execution time. The profiler has extracted only four samples, which account for 65.11% of the profile duration.</p>
<p>We want to improve that. The solution is to generate more sample data. We will multiply the number of hotels by 4 (now 20.000) to increase the number of samples. I have a simple script that generates fake data; I intentionally hide it for you to concentrate on just code optimization.</p>
<p>Again we build the program, and we launch it to get the profile.</p>
<pre v-highlightjs><code class="go" v-pre >$ go tool pprof main profile_1547641211.pb.gz
File: main
Type: cpu
Time: Jan 16, 2019 at 1:20pm (CET)
Duration: 2.06s, Total samples = 1.86s (90.28%)
Entering interactive mode (type &quot;help&quot; for commands, &quot;o&quot; for options)
(pprof)</code></pre>
<p>The samples account now for 90.28% of the profile duration! The sum of sample time is now 1.86 seconds. The number of samples is now 24! The data of our profile is more accurate. Let’s see the top view :</p>
<pre v-highlightjs><code class="go" v-pre >(pprof) top
Showing nodes accounting for 1.83s, 98.39% of 1.86s total
Showing top 10 nodes out of 45
      flat  flat%   sum%        cum   cum%
     1.48s 79.57% 79.57%      1.48s 79.57%  runtime.memmove
     0.12s  6.45% 86.02%      0.12s  6.45%  main.getRatePerNight
     0.06s  3.23% 89.25%      0.06s  3.23%  runtime.(*mspan).refillAllocCache
     0.05s  2.69% 91.94%      0.05s  2.69%  runtime.memclrNoHeapPointers
     0.04s  2.15% 94.09%      0.04s  2.15%  runtime.(*mspan).init (inline)
     0.02s  1.08% 95.16%      0.02s  1.08%  main.getExchangeRate
     0.02s  1.08% 96.24%      1.81s 97.31%  main.getTurnover
     0.02s  1.08% 97.31%      0.02s  1.08%  runtime.mmap
     0.01s  0.54% 97.85%      0.01s  0.54%  main.computeTotalTurnover
     0.01s  0.54% 98.39%      0.01s  0.54%  runtime.findrunnable</code></pre>
<div id="third-round-of-optimization-maps-v3" class="anchor"></div>
<h2 data-number="15.7"><span class="header-section-number">15.7</span> Third round of optimization: maps (V3) <a href="#third-round-of-optimization-maps-v3"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In the previous top view, we can note that the function <span v-highlightjs><code class="go" v-pre style="display: inline">main.getRatePerNight</code></span> is the second top consumer of CPU time.</p>
<p>Let’s analyze this function :</p>
<pre v-highlightjs><code class="go" v-pre >func getRatePerNight(rates []Rate, rateId uint) (uint, error) {
    var found bool
    var price uint
    for _, rate := range rates {
        if rate.Id == rateId {
            found = true
            price = rate.Price
        }
    }
    if found {
        return price, nil
    } else {
        return 0, errors.New(&quot;Impossible to retrieve rate per night&quot;)
    }
}</code></pre>
<ul>
<li><p>Here we are searching for the rate of the night.</p></li>
<li><p>For each hotel, the rates are transmitted in the reporting.</p></li>
<li><p>Each rate got an <span v-highlightjs><code class="go" v-pre style="display: inline">id</code></span> and each reservation has a <span v-highlightjs><code class="go" v-pre style="display: inline">rateId</code></span>, allowing us to retrieve the rate applied to the reservation.</p></li>
<li><p>The function consists of a for loop that will iterate over all the hotel rates to find the right one.</p></li>
<li><p>We have here a first optimization that we can make; we have to stop the loop when we found the rate. (see section <a href="#subsec:opti-loop-exit" data-reference-type="ref" data-reference="subsec:opti-loop-exit">[subsec:opti-loop-exit]</a>)</p></li>
<li><p>We just add a break statement :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >if rate.Id == rateId {
    found = true
    price = rate.Price
    break
}</code></pre>
<p>With this improvement, the program takes now 3.088s to execute (compared to 3.097s before) :</p>
<p>We can improve the search also.</p>
<p>Let denote n the number of rates for a hotel. In the worst case, we have to perform n iterations to find the rate.</p>
<p>We can use a map to improve the lookup time. That will require an important modification of the code. First, we need to create a function to generate a map of rates. <span class="math display">\text{rateId}\rightarrow\text{price}</span></p>
<p>The map will associate a <span v-highlightjs><code class="go" v-pre style="display: inline">rateId</code></span> with a price :</p>
<pre v-highlightjs><code class="go" v-pre >func RateMap(reporting HotelReporting)map[uint]uint{
    m := make(map[uint]uint)
    for _,rate := range reporting.Rates{
        m[rate.Id] = rate.Price
    }
    return m
}</code></pre>
<p>The previous function will take as argument an <span v-highlightjs><code class="go" v-pre style="display: inline">HotelReporting</code></span> and return a <span v-highlightjs><code class="go" v-pre style="display: inline">map</code></span> (keys and values are unsigned integers). We will iterate on the hotel reporting rates and fill the map progressively. ,</p>
<p>Next we have to modify the function <span v-highlightjs><code class="go" v-pre style="display: inline">getRatePerNight</code></span>. It will now look into the map to retrieve the price per night :</p>
<pre v-highlightjs><code class="go" v-pre >func getRatePerNight(rateMap map[uint]uint, rateId uint) (uint, error) {
    price, found := rateMap[rateId]
    if found {
        return price, nil
    } else {
        return 0, errors.New(&quot;Impossible to retrieve rate per night&quot;)
    }
}</code></pre>
<p>The next modification consists of calling this map generator inside our main function. For each hotel report, we will build our map and then pass it to the <span v-highlightjs><code class="go" v-pre style="display: inline">getTurnover</code></span> function :</p>
<pre v-highlightjs><code class="go" v-pre >func main(){
    //...
    for _, hotelReport := range r.HotelReportings {
        rateMap := RateMap(hotelReport)
        turnover, err := getTurnover(hotelReport,r.ExchangeRates,rateMap)
        if err != nil {
            panic(err)
        }
        groupTurnover += turnover
    }
    fmt.Printf(&quot;Group Turnover %f\n&quot;, groupTurnover)
}</code></pre>
<p>By the way, the function <span v-highlightjs><code class="go" v-pre style="display: inline">getTurnover</code></span> needs also to be modified. It must accept a third parameter: the rate map :</p>
<pre v-highlightjs><code class="go" v-pre >func getTurnover(reporting HotelReporting, exchangeRates []ExchangeRate, rateMap map[uint]uint) (float64, error) {
    //...
    for _, reservation := range reporting.Reservations {
        ratePerNight, err := getRatePerNight(rateMap, reservation.RateId)
        //...
    }
    //...
}</code></pre>
<p>We have finished. We can now compile, profile and time the execution :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build main.go
$ time ./main --rates 70 --hotels 20000 --resas 1000
real    0m3.242s
user    0m2.774s
sys     0m0.321s</code></pre>
<p>That’s not very expected. The total execution time is bigger than with the previous version! We have multiplied it by 3. What’s wrong? This drop in performance is explained by the fact that creating a map generates an overhead (the runtime has to compute the hashes and store them).</p>
<div id="tab:Impact-map-perf">
<table>
<caption>Impact of adding an Hashmap regarding execution time<span id="tab:Impact-map-perf" label="tab:Impact-map-perf">[tab:Impact-map-perf]</span></caption>
<thead>
<tr class="header">
<th style="text-align: center;">Number of rates</th>
<th style="text-align: center;"><strong>70 rates</strong></th>
<th></th>
<th style="text-align: center;"><strong>600 rates</strong></th>
<th></th>
<th style="text-align: center;"><strong>10000 rates</strong></th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td style="text-align: center;">Program version</td>
<td style="text-align: center;"><strong>v2</strong></td>
<td><strong>v3 (map)</strong></td>
<td style="text-align: center;"><strong>v2</strong></td>
<td><strong>v3 (map)</strong></td>
<td style="text-align: center;"><strong>v3 (map)</strong></td>
</tr>
<tr class="even">
<td style="text-align: center;"><strong>Total Execution time</strong></td>
<td style="text-align: center;">1.090s</td>
<td>3.242s</td>
<td style="text-align: center;"><strong>7.698s</strong></td>
<td><strong>4.209s</strong></td>
<td style="text-align: center;">26.628s</td>
</tr>
<tr class="odd">
<td style="text-align: center;"><strong>CPU User time</strong></td>
<td style="text-align: center;">0.847s</td>
<td>2.774s</td>
<td style="text-align: center;">7.493s</td>
<td>3.705s</td>
<td style="text-align: center;">23.218s</td>
</tr>
</tbody>
</table>
</div>
<p>We have only 70 rates in our data sample. Let’s test our program again with 600 rates. You can see in the table <a href="#tab:Impact-map-perf" data-reference-type="ref" data-reference="tab:Impact-map-perf">1</a> that when the number of rates increases, the relative performance of v3 is increasing.</p>
<p>Let’s take a look at the top consumers of CPU time with pprof (for 10.000 rates):</p>
<pre v-highlightjs><code class="go" v-pre >Showing nodes accounting for 12.02s, 94.50% of 12.72s total
Dropped 51 nodes (cum &lt;= 0.06s)
Showing top 10 nodes out of 36
      flat  flat%   sum%        cum   cum%
     4.51s 35.46% 35.46%      9.71s 76.34%  runtime.mapassign_fast64
     4.19s 32.94% 68.40%      4.24s 33.33%  runtime.(*hmap).newoverflow
     1.53s 12.03% 80.42%      1.53s 12.03%  runtime.memclrNoHeapPointers
     0.57s  4.48% 84.91%      0.57s  4.48%  runtime.aeshash64
     0.54s  4.25% 89.15%     11.86s 93.24%  main.RateMap
     0.21s  1.65% 90.80%      0.26s  2.04%  runtime.mapaccess2_fast64
     0.13s  1.02% 91.82%      0.62s  4.87%  main.getTurnover
     0.13s  1.02% 92.85%      0.13s  1.02%  runtime.nanotime
     0.11s  0.86% 93.71%      0.14s  1.10%  runtime.overLoadFactor (inline)
     0.10s  0.79% 94.50%      0.10s  0.79%  main.getExchangeRate</code></pre>
<p>You can see in the pprof top view that the function <span v-highlightjs><code class="go" v-pre style="display: inline">runtime.mapassign_fast64</code></span> takes much of the total CPU time (76.34% of cumulative time). It means that the step corresponding to filling the map with key-value pairs is CPU intensive, but we have still spared a lot of CPU time by implementing them.</p>
<div id="fourth-round-of-optimization-code-simplification" class="anchor"></div>
<h2 data-number="15.8"><span class="header-section-number">15.8</span> Fourth round of optimization: code simplification <a href="#fourth-round-of-optimization-code-simplification"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>If you take a close look at the code of our program, you can see that we have a useless function : <span v-highlightjs><code class="go" v-pre style="display: inline">computeTotalTurnover</code></span>. This function takes a slice of <span v-highlightjs><code class="go" v-pre style="display: inline">float64</code></span> and return the sum of the element that are into that slice:</p>
<pre v-highlightjs><code class="go" v-pre >func getTurnover(reporting HotelReporting, exchangeRates []ExchangeRate, rateMap map[uint]uint) (float64, error) {

    turnoverPerReservation := []float64{}
    // is this slice necessary
    //... for loop
    return computeTotalTurnover(turnoverPerReservation), nil
    // this function call is useless
}</code></pre>
<p>We do not need a function to do that! The function <span v-highlightjs><code class="go" v-pre style="display: inline">computeTotalTurnover</code></span> can be updated to the following version :</p>
<pre v-highlightjs><code class="go" v-pre >func getTurnover(reporting HotelReporting, exchangeRates []ExchangeRate, rateMap map[uint]uint) (float64, error) {
    var totalTurnover float64
    xr,err := getExchangeRate(reporting.HotelId,exchangeRates)
    if err != nil {
        panic(err)
    }
    for _, reservation := range reporting.Reservations {
        ratePerNight, err := getRatePerNight(rateMap, reservation.RateId)
        if err != nil {
            panic(err)
        }
        totalTurnover += float64(ratePerNight*reservation.Duration)*xr
    }
    return totalTurnover, nil
}</code></pre>
<p>Here we start by defining the variable <span v-highlightjs><code class="go" v-pre style="display: inline">totalTurnover</code></span> (of type <span v-highlightjs><code class="go" v-pre style="display: inline">float64</code></span> ) that will hold the turnover for a whole hotel reporting. Instead of putting the partial turnover for a reservation into a slice, and at the end sum the slice we just add the turnover to <span v-highlightjs><code class="go" v-pre style="display: inline">totalTurnover</code></span><strong>.</strong></p>
<p>Let’s run this version :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build main.go
$ time ./main --rates 600 --hotels 20000 --resas 1000 --profileName 600rates
real    4.14s
user    3.58s
sys     0.44s</code></pre>
<p>This small improvement has a small (but not negigable impact on performance. The version 3 of the program needed 3.705 seconds (CPU user time) the new version takes now just 3.58s.</p>
<div id="summary" class="anchor"></div>
<h2 data-number="15.9"><span class="header-section-number">15.9</span> Summary <a href="#summary"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div class="{#tab:Performance-inprovement-(600}">
<table>
<caption>Performance inprovement (600 rates, 20.000 hotels, 1.000 reservations)[[tab:Performance-inprovement-(600]]{#tab:Performance-inprovement-(600 label=“tab:Performance-inprovement-(600”}</caption>
<thead>
<tr class="header">
<th></th>
<th style="text-align: center;">v0</th>
<th style="text-align: center;">v1</th>
<th style="text-align: center;">v2</th>
<th style="text-align: center;">v3</th>
<th style="text-align: center;">v4</th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td><strong>Total Execution time</strong></td>
<td style="text-align: center;">463.21s</td>
<td style="text-align: center;">136.60s</td>
<td style="text-align: center;">16.51s</td>
<td style="text-align: center;">4.79s</td>
<td style="text-align: center;">4.57s</td>
</tr>
<tr class="even">
<td><strong>CPU User time</strong></td>
<td style="text-align: center;">460.93s</td>
<td style="text-align: center;">135.12s</td>
<td style="text-align: center;">15.83s</td>
<td style="text-align: center;">4.13s</td>
<td style="text-align: center;">3.91s</td>
</tr>
<tr class="odd">
<td><strong>CPU System time</strong></td>
<td style="text-align: center;">1.92s</td>
<td style="text-align: center;">1.02s</td>
<td style="text-align: center;">0.57s</td>
<td style="text-align: center;">0.51s</td>
<td style="text-align: center;">0.55s</td>
</tr>
</tbody>
</table>
</div>
<p>In the table [2](#tab:Performance-inprovement-(600){reference-type=“ref” reference=“tab:Performance-inprovement-(600”} you can see that I have detailed the execution time and the CPU time of each version of the program.</p>
<p>Our efforts paid because each round of optimization has improved execution time and CPU time. From the initial version to the final version (which we can still improve), the CPU User time has been divided by approximately 115.</p>
<div id="heap-profiling" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="16"><span class="header-section-number">16</span> Heap profiling <a href="#heap-profiling"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We can see in the “top” table that most of the time is spent on the function <span v-highlightjs><code class="go" v-pre style="display: inline">runtime.memmove</code></span><strong>.</strong> If we take a look at the source code of the Go runtime, this function is used to move bytes of memory from one location to another location. You can also note that in the top CPU usage, other functions of the runtime that deal with memory are called : <span v-highlightjs><code class="go" v-pre style="display: inline">runtime.(*mspan).refillAllocCache</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">runtime.memclrNoHeapPointers</code></span><strong>.</strong></p>
<p>We can infer that we have messed up with memory somewhere in our program... We have not yet done a memory profiling, but we can detect a problem with memory consumption.</p>
<p>Let’s see our three functions headers :</p>
<pre v-highlightjs><code class="go" v-pre >func getTurnover(reporting HotelReporting) (uint, error)

func computeTotalTurnover(turnoverPerReservation []uint) uint

func getRatePerNight(rates []Rate, rateId uint) (uint, error)</code></pre>
<p>Here we pass variables <strong>by value</strong> and not <strong>by reference</strong>. We are copying the data during the execution of the program. A simple optimization is to pass variables by reference.</p>
<div id="what-is-a-heap-profile" class="anchor"></div>
<h2 data-number="16.1"><span class="header-section-number">16.1</span> What is a heap profile <a href="#what-is-a-heap-profile"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A heap profile allows the developer to understand the program’s memory usage precisely. So a heap profile will collect data about allocation and deallocation points in your program. The profiler will collect the stack trace of your running program and precise memory statistics at a specific rate.</p>
<p>By the way, you can also collect at any part of your program the current memory statistics by calling :</p>
<pre v-highlightjs><code class="go" v-pre >memStats = new(runtime.MemStats)
runtime.ReadMemStats(memStats)
fmt.Println(&quot;cumulative count of heap objects allocated: %d&quot;,memStats.Mallocs)
//...</code></pre>
<div id="prerequisite-save-memory-profile" class="anchor"></div>
<h2 data-number="16.2"><span class="header-section-number">16.2</span> Prerequisite: save memory profile <a href="#prerequisite-save-memory-profile"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>We will have to change our application’s code to record the memory consumption. We will add the following lines to ask the pprof package to save a memory profile of our application :</p>
<pre v-highlightjs><code class="go" v-pre >f, err := os.Create(&quot;mem_profile.pb.gz&quot;)
if err != nil {
    log.Fatal(err)
}
runtime.GC() // get up-to-date statistics
if err := pprof.WriteHeapProfile(f); err != nil {
    log.Fatal(&quot;memory profile cannot be gathered&quot;, err)
}
defer f.Close()</code></pre>
<p>The first thing to do is to create a file that will hold our profile data. We will use the convenient method <span v-highlightjs><code class="go" v-pre style="display: inline">os.Create</code></span>.</p>
<p>Then we will ask the runtime to run a garbage collection (this will block the execution till the collection is completed). Why? Because we want our memory statistics to be more precise. The Garbage collector will get rid of the memory previously allocated and not currently used. Therefore this unused memory will not appear in our statistics.</p>
<p>Then we launch the function <span v-highlightjs><code class="go" v-pre style="display: inline">WriteHeapProfile</code></span> that will write the profile to the provided file.</p>
<div id="garbage-collector-and-its-influence-on-memory-profiling" class="anchor"></div>
<h2 data-number="16.3"><span class="header-section-number">16.3</span> Garbage collector and its influence on memory profiling <a href="#garbage-collector-and-its-influence-on-memory-profiling"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The memory profile generated by the pprof package will record the live memory allocations “as of the most recently completed garbage collection”<b-link class="citation" data-cites="go-specs" href="#go-specs" >[@go-specs]</b-link>.</p>
<p>If the runtime ran the garbage collection process in the program three times, the data reported into the profile would concern the memory allocations between the second GC run and the third.</p>
<p>If there is no garbage collection during the program, the profile will contain data about all the allocations.</p>
<p>To disable the GC, you can add this line in your go program :</p>
<pre v-highlightjs><code class="go" v-pre >debug.SetGCPercent(-1)</code></pre>
<div id="profiling-rate" class="anchor"></div>
<h2 data-number="16.4"><span class="header-section-number">16.4</span> Profiling rate <a href="#profiling-rate"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Like CPU profiling, memory profiling happens at a certain rate.</p>
<div id="our-first-memory-profile" class="anchor"></div>
<h2 data-number="16.5"><span class="header-section-number">16.5</span> Our first memory profile <a href="#our-first-memory-profile"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Let’s build our modified program and launch it :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build main.go
$ ./main --rates 600 --hotels 20000 --resas 1000</code></pre>
<p>Our memory profile has been written to disk. We can now analyze our profile with the pprof command line :</p>
<pre v-highlightjs><code class="go" v-pre >$ go tool pprof main mem_profile.pb.gz
File: main
Type: inuse_space
Time: Jan 21, 2019 at 8:49pm (CET)
Entering interactive mode (type &quot;help&quot; for commands, &quot;o&quot; for options)
(pprof) top
Showing nodes accounting for 791.52MB, 100% of 791.52MB total
      flat  flat%   sum%        cum   cum%
  791.52MB   100%   100%   791.52MB   100%  main.data
         0     0%   100%   791.52MB   100%  main.main
         0     0%   100%   791.52MB   100%  runtime.main</code></pre>
<p>First, we can note that 791.52MB allocations were profiled.</p>
<p>By entering the top command, we see the top 10 nodes with the biggest size in our profile. Here we have only three nodes! That’s the only node of our profile (that explains the 100%).</p>
<p>Then we can note that the type of the profile is <span v-highlightjs><code class="go" v-pre style="display: inline">inuse_space</code></span><strong>.</strong></p>
<div id="different-types-of-memory-statistics" class="anchor"></div>
<h2 data-number="16.6"><span class="header-section-number">16.6</span> Different types of memory statistics <a href="#different-types-of-memory-statistics"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>By default the <span v-highlightjs><code class="go" v-pre style="display: inline">inuse_space</code></span> type of statistic is displayed, but that’s not the only type of memory indicators available :</p>
<div class="list">
<p>Memory space that has been allocated but not freed. This is the memory used at the time of sampling</p>
</div>
<p>To get the profile in that mode you can type the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go tool pprof -sample_index=inuse_space main profile.pb.gz</code></pre>
<ul>
<li><span v-highlightjs><code class="go" v-pre style="display: inline">alloc_space</code></span> Memory space that is allocated now + memory that has been deallocated.</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >$ go tool pprof -sample_index=alloc_space main profile.pb.gz</code></pre>
<ul>
<li><span v-highlightjs><code class="go" v-pre style="display: inline">inuse_objects</code></span> Will display the number of objects that are allocated but not freed.</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >$ go tool pprof -sample_index=inuse_objects main profile.pb.gz</code></pre>
<ul>
<li><span v-highlightjs><code class="go" v-pre style="display: inline">alloc_objects</code></span> This is a counter of objects that are allocated + all the objects that have been freed.</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >$ go tool pprof -sample_index=alloc_space main profile.pb.gz</code></pre>
<p>You have to keep in mind that those statistics are all stored in the same profile. There are no four types of memory profiles, but just one, which is called the <strong>heap profile</strong>.</p>
<div id="usage-of-the-different-types-of-statistics" class="anchor"></div>
<h2 data-number="16.7"><span class="header-section-number">16.7</span> Usage of the different types of statistics <a href="#usage-of-the-different-types-of-statistics"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Each type of statistics will collect a different kind of information :</p>
<ul>
<li><p>The <strong>inuse</strong> statistics will give a view of memory used at a certain point of time during the program execution</p></li>
<li><p>The <strong>alloc</strong> statistics is useful to see which part of the program allocate the most memory (because it also takes into consideration the memory that was freed by the garbage collector)</p></li>
</ul>
<div id="main-sites-of-memory-allocations" class="anchor"></div>
<h2 data-number="16.8"><span class="header-section-number">16.8</span> Main sites of memory allocations <a href="#main-sites-of-memory-allocations"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>What we can see in the first profile that we have generated is that memory is used at 100% in the function <strong>main.data</strong>. This function has been built to generate the data we use in our program.</p>
<p>That’s not a surprise. Let’s see alloc_space statistics.</p>
<pre v-highlightjs><code class="go" v-pre >$ go tool pprof -sample_index=alloc_space main profile.pb.gz
File: main
Type: alloc_space
Time: Jan 21, 2019 at 9:10pm (CET)
Entering interactive mode (type &quot;help&quot; for commands, &quot;o&quot; for options)
(pprof) top
Showing nodes accounting for 1.53GB, 100% of 1.53GB total
      flat  flat%   sum%        cum   cum%
    1.53GB   100%   100%     1.53GB   100%  main.data
         0     0%   100%     1.53GB   100%  main.main
         0     0%   100%     1.53GB   100%  runtime.main</code></pre>
<p>This profile gives information about our program’s main parts that allocate memory.</p>
<div id="explore-memory-usage-with-the-interactive-view" class="anchor"></div>
<h2 data-number="16.9"><span class="header-section-number">16.9</span> Explore memory usage with the interactive view <a href="#explore-memory-usage-with-the-interactive-view"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To explore your memory profile, use the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go tool pprof -http localhost:9898 profile.pb.gz</code></pre>
<p>It will open an interactive web UI :</p>
<figure>
<b-img :src="require('@/assets/images/pprof_web_memory.png')" alt="Pprof memory consumption stats"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Pprof memory consumption stats</figcaption>
</figure>
<div id="final-note" class="anchor"></div>
<h1 data-number="17"><span class="header-section-number">17</span> Final note <a href="#final-note"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Pprof is a great tool to understand how your programs work and how they perform</p></li>
<li><p>With profiling, you can detect performance issues.</p></li>
<li><p>However, a clear and understandable program is always easier to maintain than an obscure ultra-performing program.</p></li>
</ul>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="18"><span class="header-section-number">18</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="18.1"><span class="header-section-number">18.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is the name of the package that generates profiles?</p></li>
<li><p>What are protocol buffers?</p></li>
<li><p>Which program can you use to decode a message serialized with protocol buffers?</p></li>
<li><p>What is the “call stack”?</p></li>
<li><p>In the output of the UNIX <span v-highlightjs><code class="go" v-pre style="display: inline">time</code></span> command what is the difference between “user” and “sys” time?</p></li>
<li><p>Give one optimization technique to reduce CPU usage.</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="18.2"><span class="header-section-number">18.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is the name of the package that generates profiles?</p>
<ol type="1">
<li>“runtime/pprof”</li>
</ol></li>
<li><p>What are protocol buffers?</p>
<ol type="1">
<li><p>This is a method used to serialize structured data.</p></li>
<li><p>Serialized data is smaller.</p></li>
</ol></li>
<li><p>Which program can you use to decode a message serialized with protocol buffers?</p>
<ol type="1">
<li><p>protoc</p></li>
<li><p>You can download it here https://github.com/protocolbuffers/protobuf/releases</p></li>
</ol></li>
<li><p>What is the “call stack” ?</p>
<ol type="1">
<li><p>The call stack of a program is the list of currently running functions.</p></li>
<li><p>At the beginning of the stack, you will find the <span v-highlightjs><code class="go" v-pre style="display: inline">main</code></span> function from package <span v-highlightjs><code class="go" v-pre style="display: inline">main</code></span> (program entry point)</p></li>
</ol></li>
<li><p>In the output of the UNIX <span v-highlightjs><code class="go" v-pre style="display: inline">time</code></span> command what is the difference between “user” and “sys” time?</p>
<ol type="1">
<li><p>user: This time corresponds to the amount of time the CPU (processor) was busy executing instructions outside the kernel (user space).</p></li>
<li><p>sys: This time measure corresponds to the time taken by the CPU to execute commands in the kernel space, for instance, system calls.</p></li>
</ol></li>
<li><p>Give one optimization technique to reduce CPU usage.</p>
<ol type="1">
<li>Dead code removal for instance (see “Key Takeaways” section for the abbreviated list)</li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="19"><span class="header-section-number">19</span> Key Takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Profiling = collecting detailed statistics about how a program runs</p></li>
<li><p>Profiling is different from Benchmarks. A benchmark measures the performance of one function.</p></li>
<li><p>To create a CPU profile, add those lines to your main function :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >f, err := os.Create(&quot;profile.pb.gz&quot;)
if err != nil {
    log.Fatal(err)
}
pprof.StartCPUProfile(f)
defer pprof.StopCPUProfile()</code></pre>
<ul>
<li><p>“Protocol buffers” is a method used to serialized structured data.</p></li>
<li><p>It can transform structured data into a lightweight format that can be stored and transmitted over a network.</p></li>
<li><p>The profile file generated by go is serialized using Protocol Buffers.</p></li>
<li><p>The call stack is an ordered list of active functions in a program. The call stack might grow or shrink depending on the program.</p></li>
<li><p>CPU time represents the time used by the Central Processing Unit (CPU) to execute the set of instructions defined in your program.</p></li>
<li><p>The execution time of a program can be measured by the <span v-highlightjs><code class="go" v-pre style="display: inline">time</code></span> command on UNIX systems</p></li>
<li><p>To display a profile in your web browser, you can use the following command</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >$ go tool pprof -web yourBinary profile.pb</code></pre>
<ul>
<li><p>A sample is a measurement. This measure is made at a certain time during the profiling process.</p></li>
<li><p>Some common CPU usage optimization techniques are :</p>
<ul>
<li><p>Dead code removal</p></li>
<li><p>Loop exit optimization: exit the loop as soon as possible</p></li>
<li><p>Loop invariant code removal: remove any instructions that do not depend on the loop local variables.</p></li>
<li><p>Loop fusion: if you have two loops that iterate over the same collection, you might want to merge them.</p></li>
<li><p>When an operation’s result does not vary, you might want to create a constant.</p></li>
</ul></li>
<li><p>To create a memory profile, use the following code :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >f, err := os.Create(&quot;mem_profile.pb.gz&quot;)
if err != nil {
    log.Fatal(err)
}
if err := pprof.WriteHeapProfile(f); err != nil {
    log.Fatal(&quot;memory profiling is cannot be gathered&quot;, err)
}
defer f.Close()</code></pre>
<ul>
<li>To launch the pprof interactive web interface, use this command:</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >$ go tool pprof -http localhost:9898 profile.pb.gz</code></pre>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>https://github.com/google/pprof<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
<BuyCopyInvite></BuyCopyInvite>
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap36ProgramProfiling"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap35BuildAnHttpClient'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Build an HTTP Client</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap37Context'}">
									<p><u><small>Next</small></u></p>
									<p><small>Context</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Program Profiling - Practical Go Lessons"
const description = "Profiling is a program optimization technique.“To profile a program” means to collect detailed statistics about how a program runs. Those statistics can be CPU usage, memory allocation, time spent on a program routines, number of function calls ... etc. How to profile Go programs"

export default {
  name: "Chap36ProgramProfiling",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

mounted() {
    const mathElements = window.document.getElementsByClassName("math");
    const macros = [];
    for (var i = 0; i < mathElements.length; i++) {
      const texText = mathElements[i].firstChild;
      if (mathElements[i].tagName === "SPAN") {
 		window.katex.render(texText.data, mathElements[i], {
          displayMode: mathElements[i].classList.contains('display'),
          throwOnError: true,
          macros: macros,
          fleqn: false
        });
      }
    }
  },

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
